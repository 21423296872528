.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

a {
  color: blue;
}

#zilter-course-finder-widget button {
  display: flex !important;
  height: auto !important;
  border-width: 0 !important;
  border-style: none !important;
  transition: none !important;
  border-color: none !important;
  gap: 6px !important;
}

#zilter-course-finder-widget .flowbite-dropdown button {
  background: transparent !important;
  border-radius: 0 !important;
}
#zilter-course-finder-widget ul {
  padding: 0.25rem 0 !important;
}
#zilter-course-finder-widget li {
  list-style: none !important;
}
#zilter-course-finder-widget input[type="text"] {
  height: auto !important;
  border: 1px solid rgb(209 213 219 / var(--tw-border-opacity)) !important;
}
#zilter-course-finder-widget label {
  margin: 0 !important;
}
#popover-left::before {
  content: "";
  border-style: solid;
  border-width: 8px 0 8px 10px;
  border-color: transparent transparent transparent #ffffff;
  position: absolute;
  top: 50%;
  right: -10px;
  transform: translateY(-50%);
}
